//TODO вынести цвета @import "../../../css/colors";

.button {
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0;

  -webkit-transition-duration: 0.15s; /* Safari */
  transition-duration: 0.15s;

  font-family: Montserrat, Arial, sans-serif;
  font-style: normal;

  //Размеры кнопок
  &.button-large_size {
    height: 64px;
    padding: 20px 32px;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  &.button-medium_size {
    height: 56px;
    padding: 16px 32px;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  &.button-normal_size {
    height: 48px;
    padding: 14px 32px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &.button-small_size {
    height: 40px;
    padding: 12px 24px;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &.button-extra_small_size {
    height: 32px;
    padding: 8px 24px;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  //Стили кнопок
  &.button-primary_style {
    background: #0050E6;
    box-shadow: 5px 5px 8px -1px #778BB1;
    color: #FFFFFF;

    &:hover {
      background: #0050E6;
      box-shadow: 3px 3px 10px #778BB1;
    }

    //&:focus {
    //  background: #074CCE;
    //  box-shadow: inset -4px 4px 5px rgba(69, 84, 116, 0.7), inset 4px -4px 5px rgba(134, 148, 181, 0.4);
    //}

    &:active {
      background: #0042C7;
    }

    //на макетах это click
    //&:active {
    //  background: #0343BB;
    //  box-shadow: inset -4px 4px 5px rgba(29, 52, 98, 0.6), inset 4px -4px 5px rgba(134, 148, 181, 0.4);
    //}

    &.disabled {
      cursor: default;
      background: #EBEFF5;
      box-shadow: none;

      color: #868687;

      .svg-icon {
        path {
          stroke: #868687;
        }
      }
    }
  }

  &.button-secondary_style {
    background: #F3F7FF;
    border: 1px solid #0050E6;
    box-shadow: 1px 3px 10px #ABB7D2;
    color: #001B4E;

    &:hover {
      background: #F3F7FF;
      box-shadow: 5px 5px 10px rgba(29, 52, 98, 0.3);
    }

    //&:focus {
    //  background: #DCE8FF;
    //  box-shadow: inset 4px -4px 5px rgba(153, 171, 203, 0.5);
    //}

    &:active {
      background: #CFDFFF;
      box-shadow: 1px 3px 10px #ABB7D2;
    }

    //на макетах это click
    //&:active {
    //  background: #CBDCFF;
    //  box-shadow: inset 4px -4px 5px rgba(171, 189, 227, 0.8);
    //}

    &.disabled {
      cursor: default;
      background: #EBEFF5;
      box-shadow: none;
      border: none;

      color: #868687;

      .svg-icon {
        path {
          stroke: #868687;
        }
      }
    }
  }

  &.button-tertiary_style {
    background: #FDFEFF;
    box-shadow: 3px 3px 10px rgba(171, 183, 210, 0.7);
    color: #001B4E;

    &:hover {
      background: #EFF5FF;
      box-shadow: 3px 3px 10px rgba(29, 52, 98, 0.3);
    }

    //&:focus {
    //  background: #DCE8FF;
    //  box-shadow: inset -4px 4px 4px #AFC5EC;
    //}

    &:active {
      background: #DBE7FF;
    }

    //на макетах это click
    //&:active {
    //  background: #D2E1FF;
    //  box-shadow: inset 4px -4px 4px rgba(153, 171, 203, 0.5);
    //}

    &.disabled {
      cursor: default;
      background: #EBEFF5;
      box-shadow: none;

      color: #868687;

      .svg-icon {
        path {
          stroke: #868687;
        }
      }
    }
  }

  //Размеры кнопок
  &.button-full_width {
    width: 100%;
  }

  svg.loading {
    position: absolute;
    left: -5px;
    top: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    z-index: 1;
    fill: transparent;
    stroke-linecap: square;
    animation: 5s linear button-loading-animation infinite;
  }
}

@keyframes button-loading-animation {
  0% {
    stroke-dasharray: 30% 320%;
    stroke-dashoffset: -320%;
    stroke: #08DF98;
  }
  50% {
    stroke-dasharray: 350% 320%;
    //stroke-dashoffset: -300%;
    stroke: #DFC59E;
  }
  100% {
    stroke-dasharray: 30% 320%;
    stroke-dashoffset: 30%;
    stroke: #08DF98;
  }
}