$sizes: (
        8: 8px,
        16: 16px,
        24: 24px,
        32: 32px,
        40: 40px,
        48: 48px,
        56: 56px,
        64: 64px,
        80: 80px,
        120: 120px,
);
$directions: (
        top: t,
        right: r,
        bottom: b,
        left: l,
);

@each $size, $px in $sizes {
  @each $dir, $d in $directions {
    .margin-#{$d}-#{$size} {
      margin-#{$dir}: $px;
    }
    .margin-all-#{$d}-#{$size} > *:not(:last-child) {
      margin-#{$dir}: $px;
    }
  }
}

.margin-no {
  margin: 0 !important;
}

.margin-no-vertical {
  margin: 0 auto !important;
}

// region placeholders
.margin-t-8,
.margin-all-t-8,
.margin-r-8,
.margin-all-r-8,
.margin-b-8,
.margin-all-b-8,
.margin-l-8,
.margin-all-l-8,
.margin-t-16,
.margin-all-t-16,
.margin-r-16,
.margin-all-r-16,
.margin-b-16,
.margin-all-b-16,
.margin-l-16,
.margin-all-l-16,
.margin-t-24,
.margin-all-t-24,
.margin-r-24,
.margin-all-r-24,
.margin-b-24,
.margin-all-b-24,
.margin-l-24,
.margin-all-l-24,
.margin-t-32,
.margin-all-t-32,
.margin-r-32,
.margin-all-r-32,
.margin-b-32,
.margin-all-b-32,
.margin-l-32,
.margin-all-l-32,
.margin-t-40,
.margin-all-t-40,
.margin-r-40,
.margin-all-r-40,
.margin-b-40,
.margin-all-b-40,
.margin-l-40,
.margin-all-l-40,
.margin-t-48,
.margin-all-t-48,
.margin-r-48,
.margin-all-r-48,
.margin-b-48,
.margin-all-b-48,
.margin-l-48,
.margin-all-l-48,
.margin-t-56,
.margin-all-t-56,
.margin-r-56,
.margin-all-r-56,
.margin-b-56,
.margin-all-b-56,
.margin-l-56,
.margin-all-l-56,
.margin-t-64,
.margin-all-t-64,
.margin-r-64,
.margin-all-r-64,
.margin-b-64,
.margin-all-b-64,
.margin-l-64,
.margin-all-l-64,
.margin-t-80,
.margin-all-t-80,
.margin-r-80,
.margin-all-r-80,
.margin-b-80,
.margin-all-b-80,
.margin-l-80,
.margin-all-l-80,
.margin-t-120,
.margin-all-t-120,
.margin-r-120,
.margin-all-r-120,
.margin-b-120,
.margin-all-b-120,
.margin-l-120,
.margin-all-l-120 {
}