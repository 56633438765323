@use "src/lib/css/text_mixin";

.header {
  &.H1 {
    @include text_mixin.h1;

    // если нет классов переопределяющих, устанавливает по дефолту 32px
    &:not([class*="margin-b"]) {
      margin-bottom: 32px;
    }

    &:not([class*="margin-t"]) {
      margin-top: 80px;
    }
  }

  &.H2 {
    @include text_mixin.h2;

    &:not([class*="margin-b"]) {
      margin-bottom: 32px;
    }

    &:not([class*="margin-t"]) {
      margin-top: 64px;
    }
  }

  &.H3 {
    @include text_mixin.h3;

    &:not([class*="margin-b"]) {
      margin-bottom: 32px;
    }

    &:not([class*="margin-t"]) {
      margin-top: 64px;
    }
  }

  &.H4 {
    @include text_mixin.h4;

    &:not([class*="margin-b"]) {
      margin-bottom: 26px;
    }

    &:not([class*="margin-t"]) {
      margin-top: 64px;
    }
  }
}