.loader {
    background: white no-repeat 50% 50%;
    clip-path: url(#loader);
    -webkit-clip-path: url(#loader);
    animation: spin 1.5s linear infinite;
    display: inline-block;
    width: 52px;
    height: 52px;
    color: transparent;
    overflow: hidden;

    &.loader-blue_type {
        background: conic-gradient(from 180deg at 50% 50%, #00E695 0deg, #0050E6 360deg);
    }

    &.loader-green_type {
        background: conic-gradient(from 180deg at 50% 50%, #00E695 0deg, rgba(223, 197, 158, 0) 360deg);
    }

    &.loader-big_size {
        width: 104px;
        height: 104px;
    }

    &.loader-small_size {
        width: 52px;
        height: 52px;
    }

    &.loader-extra_small_size {
        width: 24px;
        height: 24px;
    }
}

@keyframes spin { to { transform: rotate(-360deg) translatez(0); } }