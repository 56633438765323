@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');

a {
  color: #0050E6;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

//Desktop headers
@mixin header {
  font-family: Montserrat, Arial, sans-serif;
  font-style: normal;
  color: var(--header-color);
}

@mixin h1 {
  @include header;

  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
}

@mixin h2 {
  @include header;

  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

@mixin h3 {
  @include header;

  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
}

@mixin h4 {
  @include header;

  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

//Texts
@mixin text {
  font-family: Nunito Sans, Arial, sans-serif;
  font-style: normal;
  color: var(--text-color);
}

@mixin text-l {
  @include text;

  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}

@mixin text-m {
  @include text;

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-s {
  @include text;

  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

@mixin text-xs {
    @include text;

    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
}