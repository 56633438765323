@import "../src/lib/css/flex";
@import "src/lib/css/text_mixin";
@import "../src/lib/css/colors";
@import "../src/lib/css/margin";
@import "../src/lib/css/things";
@import "../src/lib/css/padding";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include text-m
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    max-width: 1200px;
    width: 100%;
  }
}
