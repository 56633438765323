@use "src/lib/css/text_mixin";

.text {
  &.text-l {
    @include text_mixin.text-l;

    &:not([class*="margin-b"]) {
      margin-bottom: 40px;
    }
  }

  &.text-m {
    @include text_mixin.text-m;

    &:not([class*="margin-b"]) {
      margin-bottom: 32px;
    }
  }

  &.text-s {
    @include text_mixin.text-s;

    &:not([class*="margin-b"]) {
      margin-bottom: 24px;
    }
  }

  &.text-xs {
    @include text_mixin.text-xs;

    &:not([class*="margin-b"]) {
      margin-bottom: 16px;
    }
  }

  &.grey {
    color: #868687;
  }
}