.background {
    background: #F5F9FF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -10000;

    filter: blur(100px);

    .green-1 {
        position: fixed;
        width: 315px;
        height: 339px;
        left: 347px;
        top: 251px;

        background: rgba(143, 233, 201, 0.32);
        filter: blur(59px);

        z-index: -10000;
    }

    .green-2 {
        position: fixed;
        width: 250px;
        height: 240px;
        left: 78px;
        top: 475px;

        background: rgba(82, 255, 194, 0.26);
        filter: blur(95.5px);

        z-index: -10000;
    }

    .green-3 {
        position: fixed;
        width: 250px;
        height: 426px;
        left: 1281px;
        top: 347px;

        background: rgba(82, 255, 194, 0.26);
        filter: blur(72px);

        z-index: -10000;
    }

    .green-4 {
        position: fixed;
        width: 322.68px;
        height: 426px;
        left: 1406.15px;
        top: 644.25px;

        background: rgba(82, 255, 194, 0.15);
        filter: blur(86.5px);
        transform: rotate(56.7deg);

        z-index: -10000;
    }

    .blue-1 {
        position: fixed;
        width: 264px;
        height: 190px;
        left: 160px;
        top: 343px;

        background: rgba(0, 175, 230, 0.27);
        filter: blur(83.5px);

        z-index: -10000;
    }

    .blue-2 {
        position: fixed;
        width: 219px;
        height: 238px;
        left: 306.84px;
        top: 274px;

        background: rgba(30, 187, 237, 0.26);
        filter: blur(69px);
        transform: rotate(87.88deg);

        z-index: -10000;
    }

    .blue-3 {
        position: fixed;
        width: 343.27px;
        height: 195.19px;
        left: 252px;
        top: 144px;

        background: rgba(0, 77, 255, 0.21);
        filter: blur(78.5px);

        z-index: -10000;
    }

    .blue-4 {
        position: fixed;
        width: 415.24px;
        height: 426px;
        left: 1303.24px;
        top: 58.64px;

        background: rgba(210, 236, 255, 0.61);
        filter: blur(69px);
        transform: rotate(56.7deg);


        z-index: -10000;
    }
}