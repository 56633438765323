.checkbox {
    position: relative;

    width: 24px;
    height: 24px;
    border: 1px solid transparent;

    &.default {
        background: #F0F5FF;
        border-color: #0050E6;
        box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 3px #ABB7D2;
        border-radius: 4px;
    }

    &.checked {
        background: #0050E6;

        box-shadow: inset -2px -2px 3px #377DFF, inset 2px 2px 3px #0F3C8E;
        border-radius: 4px;
    }

    &.disabled {
        background: #F0F5FF;
        box-shadow: none;
        border-radius: 4px;
        border-color: #9FB6FF;
        .check-item {
            path {
                stroke: #A6C4FF;
            }
        }
    }

    & > input[type="checkbox"] {
        width: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
        pointer-events: none;
    }

    .check-item {
        fill: none;
        position: relative;
        left: 16.66%;
        top: 25%;
        path {
            stroke: #00E695;
        }
    }
}