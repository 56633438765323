#modal-provider_back {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: grid;
    align-items: center;
    justify-items: center;
    align-self: center;
    justify-self: center;
    overflow: auto;
    scrollbar-gutter: stable;

    .modal-window {
        width: 50%;
    }
}