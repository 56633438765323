@use "src/lib/css/text_mixin";

.axis {
    @include text_mixin.text-xs;
}

.dot {
    fill: white;
    stroke: #0050E6;
    stroke-width: 2;
    r: 3;
}
.dot-focus {
    fill: white;
    stroke: #00E695;
    stroke-width: 3;
    r: 4;
}

#area {
    .begin {
        stop-color: #00FFBF;
        stop-opacity: 0.1;
    }

    .end {
        stop-color: #0044FF;
    }
}