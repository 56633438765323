.svg-icon {
  //TODO нужен ли?
  &.icon__loader-small {
    //background: conic-gradient(from 180deg at 50% 50%, #00E695 0deg, rgba(223, 197, 158, 0) 360deg);
    //fill: white;
  }

    &.icon-large-size {
        height: 52px;
        width: 52px;
    }
}