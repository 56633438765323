@use "src/lib/css/text_mixin";

.typical-footer {
    background: linear-gradient(2.9deg, #00E695 2.22%, #0050E6 86.21%);
    width: 100%;
    min-height: 100px;
    padding: 20px 0;


    .content {
        max-width: 1200px;
        color: #FFFFFF;

        .header, .text {
            color: #FFFFFF;
        }

        a {
            @include text_mixin.text-m;
            text-decoration: none;
            color: #FFFFFF;
        }
    }
}