.notice {
  position: relative;
  border-radius: 10px;

  padding: 16px;
  gap: 10px;

  width: 452px;

  &.full-width {
    width: 100%;
  }

  .svg-icon {
    flex-shrink: 0;
  }

  &.notice-info_type {
    background: linear-gradient(93.85deg, #E0EAFF 8.48%, #EFF4FF 95.29%);
    box-shadow: 0 4px 18px #D0D7E5;
  }

  &.notice-warning_type {
    background: linear-gradient(92.97deg, #FFFCDF 26.52%, rgba(255, 253, 234, 0.81) 101.99%);
    box-shadow: 0 4px 18px #E9E0CB;
  }

  &.notice-error_type {
    background: linear-gradient(91.64deg, #FFEEF3 11.21%, #FFF5F8 109.71%);
    box-shadow: 0 4px 18px #E5D6DA;
  }

  &.notice-success_type {
    background: linear-gradient(92.29deg, #DEFFE6 5.31%, rgba(242, 255, 245, 0.91) 110.59%);
    box-shadow: 0 4px 18px #D2E9D1;
  }
}