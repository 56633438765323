@import "../../css/flex.scss";
@import "../../css/text_mixin.scss";

.master-header {
    @include flex-row;
    padding: 8px 16px;
    flex-grow: 1;

    .master-header_item {
        @include text-l;

        position: relative;
        flex-grow: 1;
        text-align: center;
        padding: 16px 0 12px 0;

        color: #595959;

        &:before {
            content: " ";
            position: absolute;
            height: 4px;
            width: 98%;
            top: 0;
            left: 1%;
            border-radius: 2px;
            background: #595959;
        }

        &.current {
            color: #0050E6;

            &:before {
                background: #0050E6;
            }
        }

        &.done {
            color: #00DD8F;

            &:before {
                background: #00DD8F;
            }
        }

        &.error {
            color: #E60045;

            &:before {
                background: #E60045;
            }
        }
    }
}