.padding-no {
    padding: 0 !important;
}

.padding-text {
    padding: 16px 16px 40px 56px;
}

.padding-small {
    padding: 16px 16px;
}

.padding-medium {
    padding: 20px 24px;
}

.padding-large {
    padding: 40px 56px;
}