@use "src/lib/css/text_mixin";

.typical-header {
    height: 120px;
    background: white;
    width: 100%;

    .content {
        max-width: 1200px;

        .logo {
            width: 73px;
        }

        .header-links {
            a {
                @include text_mixin.text-m;
                text-decoration: none;

                -webkit-transition-duration: 0.15s; /* Safari */
                transition-duration: 0.15s;

                &:hover {
                    color: #0050E6;
                }
            }
        }
    }
}