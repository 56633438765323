@use "src/lib/css/text_mixin";

div[id^="chart_"] {
    div.header {
        @include text_mixin.h4;
    }

    div.description {
        @include text_mixin.text-s;
    }
}