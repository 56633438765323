@use "src/lib/css/text_mixin";

.tab-header-item {
    @include text_mixin.text-l;

    padding: 0 20px;
    text-align: center;
    color: #595959;

    border-bottom: 2px solid transparent;

    &:hover {
        color: #002F87;
    }

    &.active {
        color: #0050E6;
        border-bottom-color: #0050E6;
    }

    &.disabled {
        color: #868687;
        border-bottom-color: #868687;
    }
}