.switcher {
    width: 48px;
    height: 25px;
    border-radius: 22px;

    position: relative;
    overflow: hidden;

    &.default {
        background: #F0F5FF;
        box-shadow: inset -4px 4px 4px rgba(171, 183, 210, 0.4);
    }

    &.checked {
        background: #0050E6;
        box-shadow: inset 4px 4px 4px rgba(15, 60, 142, 0.8);

        .slider {
            left: 49%;
            border-color: #0050E6;
        }
    }

    &.disabled {
        background: #ECF0F9;
        box-shadow: none;

        .slider {
            border-color: rgba(171, 183, 210, 0.4);
        }
    }

    .slider {
        background: linear-gradient(135deg, #FFFFFF -4.35%, #F0F5FF 241.3%);
        border: 1px solid #9FB6FF;
        box-shadow: 0 2px 4px rgba(171, 183, 210, 0.4);
        border-radius: 50%;

        height: 23px;
        width: 23px;

        position: absolute;
        left: 1%;
    }
}