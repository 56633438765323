.tooltip {
    position: relative;
    width: fit-content;

    .tooltip-body {
        position: absolute;
        z-index: 2;
        top: 100%;
        padding: 6px 8px;
        width: 300px;

        background: #FFFFFF;
        box-shadow: 0 4px 18px rgba(171, 183, 210, 0.4);
        border-radius: 7px;

        visibility: visible;
        opacity: 1;
        transition: 0.15s linear;
        transition-property: visibility, opacity;
        cursor: initial;

        &:empty {
            visibility: hidden;
            opacity: 0;
            padding: 0;
            min-width: 0;
        }
    }
}