@use "src/lib/css/flex";
@use "src/lib/css/text_mixin";

.text-field-block {
  width: 376px;

  .text-field {
    @include flex.flex-row-center;
    position: relative;

    height: 56px;
    padding: 0 20px;

    box-shadow: inset 5px 5px 10px rgba(116, 115, 157, 0.5);
    border-radius: 11px;
    border: 1px solid transparent;

    cursor: text;

    &.text-field-default {
      background: #F4F8FF;

      &:hover {
        border: 1px solid #91B0E7;
      }

      &.focused {
        border: 1px solid #0050E6;
      }
    }

    &.text-field-disabled {
      input {
        color: #A5A4BF;
      }

      cursor: default;

      .icon-container {
        cursor: default;
      }

      .svg-icon {
        path {
          stroke: #9E9DBA;
        }
      }
    }

    &.text-field-warning {
      background: #FFFBDB;
      box-shadow: inset 5px 5px 10px rgba(227, 188, 86, 0.3);
    }

    &.text-field-error {
      background: #FFF5F8;
      box-shadow: inset 5px 5px 10px rgba(208, 132, 155, 0.3);
    }

    &.text-field-loading {

    }

    &.text-field-success {

    }

    &.no-label {
      .icon-container,
      input {
        margin-top: 0;
      }
    }

    label {
      padding-right: 2px;
      position: absolute;
      top: calc(50% - 10px);

      @include text_mixin.text-m;
      color: #807E87;

      transform-origin: 0 50%;
      transition: font-size 0.15s, transform 0.15s ease-in-out;
    }

    input {
      @include flex.flex-row;
      @include text_mixin.text-m;
      align-items: center;
      height: 24px;
      width: 100%;
      border: none;
      outline: none;
      background: transparent;

      margin-top: 10px;
      margin-right: 10px;
    }

    &.valued label,
    &.focused label {
      transform: translate(0px, -10px);
      font-size: 12px;
      line-height: 16px;
      padding-right: 2px;
    }

    .icon-container {
      cursor: pointer;
      margin-top: 10px;
    }
  }

  .field-state-text-block {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #807F87;
  }

  .password-additional-block {
    @include text_mixin.text;
    color: #0050E6;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    div {
      cursor: pointer;
    }
  }
}