.radio-button {
    height: 24px;
    width: 24px;
    background: #F0F5FF;

    border-radius: 50%;
    border: 1px solid transparent;

    &.default {
        box-shadow: inset -3px -3px 4px #FFFFFF, inset 3px 3px 3px #ABB7D2;
        border-color: #0050E6;
    }

    &.disabled {
        background: #F0F5FF;
        border-color: #9FB6FF;
    }

    .radio-button-selected {
        width: 9.5px;
        height: 9.5px;
        border-radius: 50%;
        background: #0050E6;
    }
}